.LogInBtn {
  position: fixed;
  z-index: 1;
  border: none;
  top: 16px;
  right: 60px;
  padding: 3px 8px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  overflow: hidden;
  color: white;
  display: flex;
  column-gap: 6px;
  font-size: 13px;
}

.LogInBtn a {
  color: inherit;
}
