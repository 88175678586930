.CommentForm {
  line-height: 0;
}

.CommentForm textarea {
  width: 100%;
  height: 28px;
  padding: 4px 12px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 14px;
  font-family: inherit;
  font-size: inherit;
  box-sizing: border-box;
  color: inherit;
  resize: none;
}

.CommentForm textarea::-webkit-scrollbar {
  display: none;
}

.dark-mode .CommentForm textarea {
  background-color: hsl(240, 3%, 15.7%);
  border: 1px solid hsl(240, 3%, 21.6%);
}

.CommentForm textarea:focus {
  outline: none;
}
