.ThemeSwitch {
  cursor: pointer;
  border: none;
  background-color: hsl(0, 0%, 35%);
  color: white;
  padding: 2px;
  line-height: 0;
  border-radius: 50%;
  width: 32px;
  min-height: 32px;
}

.dark-mode .ThemeSwitch {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 20%);
}

.ThemeSwitch .sun {
  display: none;
}

.dark-mode .ThemeSwitch .sun {
  display: inline-block;
}

.dark-mode .ThemeSwitch .moon {
  display: none;
}
