.Navigator {
  display: flex;
  height: 32px;
  flex: 1;
  max-width: 100%;
  position: relative;
}

.Navigator input {
  border: 1px solid lightgray;
  padding: 0 8px;
  margin-right: 4px;
  border-radius: 3px;
  font-family: inherit;
  box-sizing: border-box;
  flex: 1;
  font-size: 18px;
  min-width: 0;
  height: 100%;
}

.dark-mode .Navigator input {
  background-color: hsl(240, 3%, 15.7%);
  border: 1px solid hsl(240, 3%, 21.6%);
  color: rgb(239, 239, 241);
}

.Navigator input::placeholder {
  color: rgb(160, 160, 160);
}

.Navigator input:focus {
  outline: none;
  border: 1px solid orangered;
}

.Navigator .go-btn {
  background-color: orangered;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: "SFUIText-Medium";
  cursor: pointer;
  padding: 0 10px;
  font-size: 18px;
}

.Navigator .go-btn:hover {
  background-color: hsl(16, 100%, 47%);
}

.Navigator .error-msg {
  position: absolute;
  bottom: -32px;
  background-color: rgba(255, 68, 0, 0.85);
  padding: 4px 8px;
  border: 1px solid orangered;
  color: white;
  border-radius: 3px;
  z-index: 1;
  font-family: "SFUIText-Regular";
  white-space: nowrap;
  visibility: hidden;
}

.flash-in {
  animation: flash-in 3s;
}

@keyframes flash-in {
  0% {
    visibility: visible;
  }
  80% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
