.Home {
  display: flex;
  height: 100%;
}

.Home .Sidebar .arrow {
  width: 80px;
  margin-right: 8px;
  margin-bottom: -12%;
  margin-left: 12px;
  padding-bottom: 12px;
}

.dark-mode .Home .Sidebar .arrow {
  filter: invert();
}

.Home .Sidebar .how-to-1,
.Home .Sidebar .how-to-2 {
  font-family: "IndieFlower-Regular";
  font-size: 28px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Home .Sidebar .how-to-2 {
  flex-direction: column;
}

.Home .Sidebar .how-to-1 .msg,
.Home .Sidebar .how-to-2 .msg {
  padding: 0 4px;
  flex: 1;
}

.Home .Sidebar .how-to-2 .msg code {
  font-size: 22px;
  font-weight: 400;
  background-color: hsl(218, 22%, 70%, 25%);
  padding: 0 8px;
  border-radius: 6px;
}

.Home .Sidebar .how-to-2 .big {
  text-align: center;
  font-size: 48px;
  margin-bottom: 16px;
}

.Home .Sidebar .url {
  max-width: 100%;
}

.Home .main {
  align-items: center;
}

.Home .main .logo {
  margin-top: 10vh;
  width: min(400px, 100%);
  padding: 0 16px;
  box-sizing: border-box;
  filter: drop-shadow(0 0 80px rgba(255, 255, 255, 0.25));
}

.Home .main .tag-line {
  margin-top: 12px;
  font-size: min(7vw, 24px);
  padding: 0 20px;
  text-align: center;
}

.Home .main .me {
  margin-top: auto;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: gray;
  font-family: inherit;
}

.Home .main .me:hover {
  text-decoration: none;
}

.Home .main .me .github {
  height: 32px;
}

.dark-mode .Home .main .me .github {
  filter: invert();
}

.Home .ThemeSwitch {
  position: fixed;
  z-index: 1;
  top: 16px;
  right: 18px;
}

.Home .main .active-threads {
  max-width: 100%;
  box-sizing: border-box;
  padding: 64px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

.Home .main .active-threads h1 {
  align-self: center;
}

.Home .main .active-threads .thread {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Home .main .active-threads .thread .title {
  font-size: 16px;
  color: inherit;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Home .main .active-threads .thread .subtext {
  color: gray;
}
