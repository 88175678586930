.Thread {
  display: flex;
  height: 100%;
}

.Thread .Sidebar p {
  margin: 6px 0 4px 0;
}

.Thread .Sidebar .info-box,
.Thread .Sidebar .stickied {
  background-color: white;
  border-radius: 6px;
  padding: 10px 14px;
  border: 1px solid lightgray;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}

.dark-mode .Thread .info-box,
.dark-mode .Thread .stickied {
  background-color: #18181b;
  border-color: #232328;
  color: rgb(239, 239, 241);
}

.Thread .Sidebar .info-box .title-bar {
  border-bottom: 1px solid lightgray;
  padding-bottom: 7px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
}

.dark-mode .Thread .Sidebar .info-box .title-bar {
  border-bottom: 1px solid gray;
}

.Thread .Sidebar .info-box .title {
  font-size: 22px;
  margin-bottom: 3px;
  cursor: pointer;
  color: black;
  font-family: "SFUIText-Regular";
  pointer-events: none;
}

.dark-mode .Thread .Sidebar .info-box .title {
  color: rgb(239, 239, 241);
}

.Thread .Sidebar .info-box .title-bar .flair {
  background-color: #f0f2f5;
  padding: 0 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  margin: 0 3px;
}

.dark-mode .Thread .Sidebar .info-box .title-bar .flair {
  background-color: hsl(240, 3%, 39.2%);
  border: 1px solid hsl(240, 3%, 39.2%);
  color: rgb(239, 239, 241);
}

.Thread .Sidebar .info-box .title-bar .by-line .flair-emoji {
  height: 14.5px;
  margin-bottom: -3px;
}

.Thread .Sidebar .info-box .by-line {
  color: gray;
  font-size: 12px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex-wrap: wrap;
}

.Thread .Sidebar .info-box .by-line a {
  color: gray;
  font-family: "SFUIText-Regular";
}

.Thread .Sidebar .info-box .title-bar .link-flair {
  background-color: rgb(175, 175, 175);
  font-family: "SFUIText-Regular";
  font-size: 14px;
  color: rgb(239, 239, 241);
  padding: 1px 6px;
  border-radius: 3px;
}

.dark-mode .Thread .Sidebar .info-box .title-bar .link-flair {
  background-color: hsl(240, 3%, 39.2%);
}

.Thread .Sidebar .info-box .title-bar .link-flair .flair-emoji {
  height: 16px;
  margin-bottom: -3px;
}

.Thread .Sidebar .info-box .selftext {
  overflow: auto;
}

.Thread .Sidebar .info-box .stats {
  display: flex;
  column-gap: 10px;
  margin-top: 8px;
}

.Thread .Sidebar .info-box .stats .badge {
  display: flex;
  align-items: center;
  color: gray;
  font-family: "SFUIText-Light";
  font-size: 14px;
  column-gap: 4px;
  min-width: 60px;
}

.Thread .Sidebar .info-box .stats .speed,
.Thread .Sidebar .info-box .stats .view-on-reddit {
  margin-left: auto;
  align-self: flex-end;
  color: gray;
  text-align: center;
}

.Thread .Sidebar .stickied .by-line {
  color: gray;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
}

.Thread .Sidebar .stickied .author {
  color: gray;
}

.Thread .Sidebar .stickied .by-line .indicator {
  color: #75d377;
}

.Thread .Sidebar .stickied .timestamp {
  margin-left: auto;
  color: gray;
  font-family: inherit;
}

.Thread .Sidebar .stickied .timestamp:hover {
  text-decoration: none;
}

.Thread .Sidebar .stickied .body {
  overflow: auto;
  max-width: 100%;
}

.Thread .Sidebar .stickied .body .gif {
  border-radius: 8px;
  margin-top: 6px;
  max-width: 100%;
  max-height: 256px;
}

.Thread .Sidebar .not-found-msg {
  font-size: 22px;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;
  text-align: center;
  padding: 0 16px;
  max-width: 300px;
  line-height: 30px;
}

.dark-mode .Thread .Sidebar .not-found-msg {
  color: rgb(239, 239, 241);
}

.Thread .main .no-comments-msg {
  position: absolute;
  top: 50%;
  align-self: center;
}

.dark-mode .Thread .main .no-comments-msg {
  color: rgb(239, 239, 241);
}

.Thread .settings {
  position: fixed;
  z-index: 1;
  top: 16px;
  right: 18px;
  width: 32px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  color: white;
  overflow: hidden;
  pointer-events: none;
}

.Thread .settings > * {
  pointer-events: auto;
}

.Thread .settings button {
  padding: 0;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  background-color: hsl(0, 0%, 35%);
  font-family: inherit;
  color: inherit;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode .Thread .settings button {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 20%);
}

.Thread .settings button:hover {
  filter: brightness(1.2);
}

.Thread .settings button:active {
  filter: brightness(1);
}

.Thread .settings .toggle {
  min-height: 32px;
  border-radius: 16px;
}

.Thread .settings .toggle ~ * {
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
}

.Thread .settings:focus-within .toggle {
  pointer-events: none;
  filter: brightness(0.65);
}

.Thread .settings:focus-within .toggle ~ * {
  visibility: visible;
  opacity: 1;
}

.Thread .settings .delay-rocker {
  width: 32px;
  min-height: 80px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 12px;
  font-family: "SFUIText-Regular";
  background-color: hsl(0, 0%, 30%);
}

.dark-mode .Thread .settings .delay-rocker {
  background-color: hsl(0, 0%, 80%);
  color: hsl(0, 0%, 30%);
}

.Thread .settings .delay-rocker button {
  font-size: 16px;
  line-height: 0;
  flex: 3;
}

.Thread .settings .delay-rocker .seconds {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Thread .settings .popout-chat {
  min-height: 32px;
  border-radius: 16px;
}

.Thread.popout .SidebarContainer {
  display: none;
}

@media only screen and (max-width: 960px) {
  .Thread .Sidebar .info-box .title {
    pointer-events: initial;
  }
}
