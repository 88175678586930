:root {
  --doc-height: 100%;
}

body {
  height: 100vh; /* fallback for Js load */
  height: var(--doc-height);
  overflow: hidden;
}

body:has(.App.dark-mode) {
  background-color: #18181b;
}

body * {
  touch-action: pan-x pan-y;
}

#root,
.App {
  height: 100%;
}

.App.dark-mode {
  color: rgb(239, 239, 241);
}

body ::-webkit-scrollbar-track {
  background-color: transparent;
}

.main ::-webkit-scrollbar {
  width: 8px;
}

.main ::-webkit-scrollbar-thumb {
  background-color: rgba(127, 127, 127, 0.5);
  border-radius: 4px;
  background-clip: content-box;
  height: 120px;
}

.Sidebar ::-webkit-scrollbar {
  display: none;
}

.Sidebar * {
  scrollbar-width: none;
}

.main ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(127, 127, 127);
}

.hidden {
  visibility: hidden;
}

p {
  margin: 0;
}

h1 {
  font-weight: 500;
  font-family: "SFUIText-Regular";
  margin: 8px 0;
  font-size: 1.6em;
}

ul,
ol {
  margin: 6px 0;
  padding-left: 30px;
}

table {
  border: 2px solid #e8e2e6;
  border-collapse: collapse;
  margin: 8px 0;
}

.dark-mode table {
  border: 2px solid #555355;
}

td,
th {
  border: 1px solid #e8e2e6;
  padding: 4px 8px;
}

.dark-mode td,
.dark-mode th {
  border: 1px solid #555355;
}

th {
  text-align: center;
}

strong {
  font-family: "SFUIText-Medium";
  font-weight: 500;
}

a {
  text-decoration: none;
  color: rgb(29, 155, 240);
  font-family: "SFUIText-Regular";
}

a:hover {
  text-decoration: underline;
}

hr {
  margin: 10px 0;
}

blockquote {
  margin: 10px 6px;
  padding-left: 8px;
  border-left: 4px solid rgb(188, 188, 188);
  color: rgb(109, 109, 109);
}

.dark-mode blockquote {
  color: rgb(154, 154, 154);
  border-left: 4px solid rgb(86, 86, 86);
}

button {
  margin: 0;
}

.main {
  flex: 2;
  height: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.dark-mode .main {
  background-color: #18181b;
}

.dark-mode hr {
  border-color: gray;
}

.Throbber {
  position: fixed;
  bottom: 75px;
  right: 50px;
  z-index: 2;
}
