.Comment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}

.replies-container {
  animation: fade-in 0.4s;
}

.Comment .bubble {
  background-color: #f0f2f5;
  margin: 4px 0;
  border-radius: 18px;
  padding: 8px 12px;
  box-sizing: border-box;
  max-width: 100%;
}

.dark-mode .Comment .bubble {
  background-color: hsl(240, 3%, 23.5%);
  color: rgb(239, 239, 241);
}

.Comment .bubble.is-me {
  background-color: #057cfe;
}

.Comment .bubble.is-me,
.Comment .bubble.is-me .info .author,
.Comment .bubble.is-me .info .score,
.Comment .bubble.is-me .info .reply-btn,
.Comment .bubble.is-me .info .timestamp {
  color: white;
}

.Comment .info {
  height: 16px;
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
}

.Comment .info .left {
  display: flex;
  overflow: hidden;
}

.Comment .info .author {
  font-family: "SFUIText-Medium";
  color: black;
  text-decoration: none;
  margin-right: 10px;
}

.dark-mode .Comment .info .author {
  color: rgb(239, 239, 241);
}

.Comment .info a.author:hover {
  text-decoration: underline;
}

.Comment .info .author.deleted {
  color: gray;
}

.Comment .info .score {
  display: flex;
  align-items: center;
  color: gray;
  margin-right: 8px;
  column-gap: 2px;
  height: 100%;
  line-height: 0;
}

.Comment .info .flair {
  background-color: white;
  color: gray;
  padding: 0 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 14px;
}

.dark-mode .Comment .info .flair {
  background-color: hsl(240, 3%, 39.2%);
  border: 1px solid hsl(240, 3%, 39.2%);
  color: rgb(239, 239, 241);
}

.Comment .bubble.is-me .info .flair {
  background-color: #3e9bff;
  border: 1px solid #3e9bff;
  color: white;
}

.Comment .info .flair .flair-emoji {
  height: 12px;
  margin-bottom: -2px;
}

.Comment .info .timestamp {
  margin-left: auto;
  color: gray;
  min-width: 24px;
  text-align: right;
  font-family: inherit;
}

.Comment .info .reply-btn {
  color: gray;
  margin-right: 8px;
  display: flex;
  align-items: center;
  column-gap: 3px;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  line-height: 0;
}

.dark-mode .Comment .info .timestamp,
.dark-mode .Comment .info .score,
.dark-mode .Comment .info .reply-btn,
.dark-mode .Comment .info .author.deleted {
  color: hsl(240, 3%, 60%);
}

.Comment .info .timestamp:hover {
  text-decoration: none;
}

.Comment .body {
  margin-top: 2px;
  word-wrap: break-word;
}

.Comment .bubble.is-me .body a {
  color: white;
}

.Comment .body p {
  line-height: 20px;
}

.Comment .body .emote {
  height: 18px;
  margin-bottom: -4px;
}

.Comment .body .gif {
  border-radius: 8px;
  margin-top: 6px;
  max-width: 100%;
  max-height: 256px;
}

.Comment .connector {
  margin: 0 0 6px 4px;
  min-width: 13.5px;
  background-color: transparent;
  border: none;
  border-left: 1.5px solid #e2e4e7;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.dark-mode .Comment .connector {
  border-left: 1.5px solid #383839;
}

.Comment .replies:empty + .connector {
  display: none;
}

.Comment .connector:hover {
  border-left: 1.5px solid hsl(216, 20%, 85%);
}

.dark-mode .Comment .connector:hover {
  border-left: 1.5px solid #59595b;
}

.Comment .replies-container {
  display: none;
  flex-direction: row-reverse;
  max-width: 100%;
  width: 100%;
}

.Comment.show-children .replies-container {
  display: flex;
}

.Comment .replies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
}

.Comment .replies-container.collapsed {
  height: 24px;
}

.Comment .replies-container.collapsed .connector::after {
  content: "• • •";
  padding: 0 16px;
  min-height: 16px;
  background-color: #f0f2f5;
  border-radius: 8px;
  color: gray;
  margin-left: 12px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.dark-mode .Comment .replies-container.collapsed .connector::after {
  background-color: hsl(240, 3%, 23.5%);
}

.Comment .replies-container.collapsed .connector:hover::after {
  background-color: hsl(216, 20%, 90%);
}

.dark-mode .Comment .replies-container.collapsed .connector:hover::after {
  background-color: hsl(240, 3%, 27%);
}

.Comment .replies-container.collapsed .replies {
  visibility: hidden;
}

.Comment .info button {
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0;
  line-height: 0;
}

.Comment .info button:not(:disabled) {
  cursor: pointer;
}

.Comment .bubble:not(.is-me) .score.upvoted .upvote,
.Comment .bubble:not(.is-me) .score.upvoted .num {
  color: rgb(255, 69, 0);
}

.Comment .bubble:not(.is-me) .score.downvoted .downvote,
.Comment .bubble:not(.is-me) .score.downvoted .num {
  color: rgb(113, 147, 255);
}

.Comment .CommentForm {
  width: 100%;
  padding: 4px 0;
}

@keyframes fade-in {
  from {
    opacity: 0.2;
  }
}

@media only screen and (max-width: 400px) {
  .Comment .connector {
    margin-left: 0;
  }
}
