.SidebarContainer {
  flex: 1;
  min-width: 340px;
}

.Sidebar {
  background-color: #e0e4eb;
  border-right: 1px solid lightgray;
  height: 100%;
  box-sizing: border-box;
  display: flex;
}

.Sidebar .drawer {
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1;
  max-width: 100%;
  min-width: 0;
  overflow: auto;
  box-sizing: border-box;
}

.Sidebar .drawer .sidebar-content {
  flex: 1;
  overflow: auto;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Sidebar .top-bar {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: flex-start;
  column-gap: 16px;
}

.Sidebar .top-bar a.logo {
  height: 32px;
  line-height: 0;
}

.Sidebar .top-bar .logo img {
  height: 100%;
}

.dark-mode .Sidebar {
  background-color: #0e0e10;
  border-color: #18181b;
}

.SidebarContainer .collapser {
  height: 32px;
  width: 32px;
  padding: 0;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  display: none;
  border-radius: 50%;
}

.SidebarContainer .collapser.in-line {
  background-color: transparent;
  color: inherit;
}

.SidebarContainer .collapser.floating {
  position: fixed;
  top: 16px;
  left: 16px;
  z-index: 1;
  background-color: hsl(0, 0%, 35%);
  color: white;
}

.SidebarContainer .collapser.floating:hover {
  filter: brightness(1.2);
}

.dark-mode .SidebarContainer .collapser.floating {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 20%);
}

.SidebarContainer .me {
  margin-top: auto;
  align-self: center;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: gray;
  font-size: 12px;
  font-family: inherit;
}

.SidebarContainer .me .github {
  height: 20px;
}

.dark-mode .SidebarContainer .me .github {
  filter: invert(1);
}

@media only screen and (max-width: 960px) {
  .SidebarContainer {
    flex: 0;
    min-width: 0;
  }

  .SidebarContainer .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 0.7s;
    pointer-events: none;
  }

  .Sidebar {
    width: min(calc(100% - 32px), 500px);
    position: absolute;
    z-index: 2;
    transition: transform 0.5s;
    opacity: 0.9999999;
  }

  .SidebarContainer:not(.collapsed) .overlay {
    background-color: hsl(218, 50%, 10%, 0.2);
    backdrop-filter: blur(10px);
    pointer-events: initial;
  }

  .dark-mode .SidebarContainer:not(.collapsed) .overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .SidebarContainer.collapsed .Sidebar {
    transform: translateX(-100%);
  }

  .SidebarContainer .collapser {
    display: flex;
  }
}
