.Chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.Chat .chat-box {
  overflow: auto;
  flex: 1;
  z-index: 1;
}

.Chat .chat-box > div {
  padding: 0 12px;
  box-sizing: border-box;
}

.Chat .follow-btn {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("../images/arrow-circle-down-fill.svg");
  background-size: 100%;
}

.Chat .CommentForm {
  padding: 12px 12px;
}

@media only screen and (max-width: 400px) {
  .Chat .chat-box > div {
    padding: 0 8px;
  }

  .Chat .CommentForm {
    padding: 8px 8px;
  }
}
