.Throbber {
  height: 1.5rem;
  width: 1.5rem;
  transform: translateX(-50%) translateY(-50%);
}

.Throbber .container {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
}

.Throbber .block {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
}

.Throbber .block > .item {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background: orangered;
  animation: move 0.5s linear infinite;
}

.Throbber .block > .item:nth-of-type(1) {
  top: -1.5rem;
  left: -1.5rem;
  animation-delay: 0s;
}

.Throbber .block > .item:nth-of-type(2) {
  top: -1.5rem;
  left: 0;
  animation-delay: -0.0625s;
}

.Throbber .block > .item:nth-of-type(3) {
  top: -1.5rem;
  left: 1.5rem;
  animation-delay: -0.125s;
}

.Throbber .block > .item:nth-of-type(4) {
  top: 0;
  left: 1.5rem;
  animation-delay: -0.1875s;
}

.Throbber .block > .item:nth-of-type(5) {
  top: 1.5rem;
  left: 1.5rem;
  animation-delay: -0.25s;
}

.Throbber .block > .item:nth-of-type(6) {
  top: 1.5rem;
  left: 0;
  animation-delay: -0.3125s;
}

.Throbber .block > .item:nth-of-type(7) {
  top: 1.5rem;
  left: -1.5rem;
  animation-delay: -0.375s;
}

.Throbber .block > .item:nth-of-type(8) {
  top: 0;
  left: -1.5rem;
  animation-delay: -0.4375s;
}

@keyframes move {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
